import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Vids from "../components/vids"

const Videos = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title="Videos" />
      <Vids />
    </Layout>
  )
}

export default Videos
