import React from "react"
const Video = ({ videoSrcURL, videoTitle, ...props }) => (
  <div className="videoFrame">
    <iframe
      src={`https://www.youtube-nocookie.com/embed/${videoSrcURL}`}
      title={videoTitle}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      width="100%"
      height="100%"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  </div>
)
export default Video
