import React, { useEffect, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Article } from "./layout"
import Video from "./video"
import sizeMe from "react-sizeme"
import StackGrid from "react-stack-grid"

const VideoHeader = title => {
  return (
    <>
      <hr />
      <h1 style={{ margin: 0 }}>
        <a
          style={{ fontSize: "1.25em", margin: "0px" }}
          className="ytLink"
          title="See all videos"
          href="https://www.youtube.com/@decaffeinateddesigns"
        >
          {title.title}
        </a>
      </h1>
      <hr />
    </>
  )
}

const Vids = ({ size }) => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: { fields: { slug: { regex: "/video-/" } } }
        sort: { fields: frontmatter___order, order: ASC }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              vidId
              title
              description
              order
            }
          }
        }
      }
    }
  `)
  const grid = useRef(null)
  useEffect(() => {
    requestAnimationFrame(() => {
      if (grid.current) {
        grid.current.updateLayout()
      }
    })
  }, [grid])
  const allVids = data.allMarkdownRemark.edges
  return (
    <Article>
      <VideoHeader title="Videos" />
      <StackGrid
        gridRef={r => (grid.current = r)}
        columnWidth={size.width <= 960 ? "100%" : "50%"}
        duration={0}
        gutterWidth={15}
        gutterHeight={10}
        appearDelay={60}
      >
        {allVids.map(vid => (
          <Video
            key={vid.node.fields.slug}
            videoSrcURL={vid.node.frontmatter.vidId}
            videoTitle={vid.node.frontmatter.title}
          />
        ))}
      </StackGrid>
      <div className="spaced-row">
        <p>
          Want to see more? Visit my {" "}
          <a href="https://www.youtube.com/@decaffeinateddesigns">YouTube Channel</a>!
        </p>
      </div>
    </Article>
  )
}

export default sizeMe()(Vids)
